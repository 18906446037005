import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { RedirectProvider } from "./Contexts/RedirectContext";
import GtmScript from "../src/Utility/GtmScript";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
const HomePage = lazy(() => import("../src/Components/Pages/HomePage"));
const MedicareThankYou = lazy(() => import("../src/Components/Pages/Thankyou/MedicareThankYou"));
const MedicareThankYouv2 = lazy(() => import("../src/Components/Pages/Thankyou/SPH_CDV_S11A/MedicareThankYou"));
const GreencardThankyou = lazy(() => import("../src/Components/Pages/Thankyou/GreencardThankyou"));
const PrivacyPolicy = lazy(() => import("../src/Components/Pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("../src/Components/Pages/TermsOfUse"));
const CookiesPolicy = lazy(() => import("../src/Components/Pages/CookiesPolicy"));
const SPR_SPH_V11A = lazy(() => import("./Components/Pages/Splits/SPR_SPH_V11A"));
const SPH_CDV_S11A = lazy(() => import("./Components/Pages/Splits/SPH_CDV_S11A"));
const Medicare = lazy(() => import("./Components/Pages/Thankyou/SPR_SPH_V11A/MedicareThankYou"));
const HelmetBasedOnDomain = lazy(() => import("./Components/Includes/Layouts/common/HelmetBasedOnDomain"));
const VeronicaTermsOfUse = lazy(() => import("../src/Components/Includes/Layouts/SPH_CDV_S11A/TermsOfUse"));
const VeronicaCookiesPolicy = lazy(() => import("../src/Components/Includes/Layouts/SPH_CDV_S11A/CookiesPolicy"));
const VeronicaPrivacyPolicy = lazy(() => import("../src/Components/Includes/Layouts/SPH_CDV_S11A/PrivacyPolicy"));
const SPH_CDV2_2A = lazy(() => import("./Components/Pages/Splits/SPH_CDV2_2A"));
const PrivacyPolicyEnglish = lazy(() => import("./Components/Pages/PrivacyPolicyEnglish"));
const TermsOfUseEnglish = lazy(() => import("./Components/Pages/TermsOfUseEnglish"));
const CookiesPolicyEnglish = lazy(() => import("./Components/Pages/CookiesPolicyEnglish"));
const RedirectWithQueryParams = lazy(() => import("./Utility/RedirectWithQueryParams"));
const SPR_SPH_V11B = lazy(() => import("./Components/Pages/Splits/SPR_SPH_V11B"));
const CDV_SPH_V11B = lazy(() => import("./Components/Pages/Splits/CDV_SPH_V11B"));
const SPH_ETE_CDV3 = lazy(() => import("./Components/Pages/SPH_ETE_CDV3/SPH_ETE_CDV3"));
const IfOnline = lazy(() => import("./Components/Pages/SPH_ETE_CDV3/IfOnline"));
const PersonalDetails = lazy(() => import("./Components/Pages/SPH_ETE_CDV3/PersonalDetails"));
const DoctorDetails = lazy(()=> import("./Components/Pages/SPH_ETE_CDV3/DoctorDetails"))
const Thankyou = lazy(()=> import("./Components/Pages/SPH_ETE_CDV3/Thankyou"))
const CPS_ETE_V1 = lazy(()=> import("./Components/Pages/CPS_ETE_V1/CPS_ETE_V1"))

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  return (
    <>
    <HelmetBasedOnDomain />
    <VisitorParamsProvider value={{ visitorParameters }}>
      <RedirectProvider value={{ redirectUrl }}>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Switch>
              {/* <Layout> */}
                <Route exact path="/" component={HomePage} />
                <Route exact path={"/medicare-thankyou"} component={MedicareThankYouv2} />
                <Route exact path={"/medicare-thankyou"} component={MedicareThankYou} />
                <Route exact path={"/medicare"} component={Medicare} />
                {/* <Route exact path={"/medicare-thankyouV2"} component={MedicareThankYouv2} /> */}
                <Route exact path={"/greencard-thankyou"} component={GreencardThankyou} />
                <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
                <Route exact path={"/terms-of-use"} component={TermsOfUse} />
                <Route exact path={"/cookies-policy"} component={CookiesPolicy} />
                <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
                <Route exact path={["/SPR_SPH_V11A"]} component={SPR_SPH_V11A}/>
                <Route exact path={["/SPH_CDV_S11A"]} component={SPH_CDV_S11A}/>
                <Route exact path={["/SPH_CDV2_2A"]} component={SPH_CDV2_2A}/>
                <Route exact path={["/privacy-policy-en"]} component={PrivacyPolicyEnglish}/>
                <Route exact path={["/terms-of-use-en"]} component={TermsOfUseEnglish}/>
                <Route exact path={["/cookies-policy-en"]} component={CookiesPolicyEnglish}/>
                <Route exact path={"/veronica-privacy-policy"} component={VeronicaPrivacyPolicy} />
                <Route exact path={"/veronica-terms-of-use"} component={VeronicaTermsOfUse} />
                <Route exact path={"/veronica-cookies-policy"} component={VeronicaCookiesPolicy} />
                <Route exact path={["/SPR_SPH_V11B"]} component={SPR_SPH_V11B}/>
                <Route exact path={["/CDV_SPH_V11B"]} component={CDV_SPH_V11B}/>
                <Route exact path={["/SPH_ETE_CDV3"]} component={SPH_ETE_CDV3}/>
                <Route exact path={["/if_online"]} component={IfOnline}/>
                <Route exact path={["/personal-details"]} component={PersonalDetails}/>
                <Route exact path={["/doctor-details"]} component={DoctorDetails}/>
                <Route exact path={["/thankyou"]} component={Thankyou} />
                <Route exact path={["/beneficiossalud/CPS_ETE_V1"]} component={CPS_ETE_V1} />
                <Route path="*"><RedirectWithQueryParams/></Route>
                <Redirect to="/" />
              {/* </Layout> */}
            </Switch>
          </Suspense>
        </BrowserRouter>
      </RedirectProvider>
    </VisitorParamsProvider>
    <GtmScript/>
    </>
  );
};

export default App;
