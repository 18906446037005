import React, { lazy } from 'react';


const SPR_SPH_V11A = lazy(() => import("./Components/Pages/Splits/SPR_SPH_V11A"));
const SPH_CDV_S11A = lazy(() => import("./Components/Pages/Splits/SPH_CDV_S11A"));
const SPH_ETE_CDV3 = lazy(() => import("./Components/Pages/SPH_ETE_CDV3/SPH_ETE_CDV3"));
const HomePage = lazy(() => import("../src/Components/Pages/HomePage"));

const AppSplitList = (props) => {

    const SplitListMap = {
       
        'SPR_SPH_V11A': SPR_SPH_V11A,
        'SPH_CDV_S11A':SPH_CDV_S11A,
        'SPH_ETE_CDV3':SPH_ETE_CDV3
        
    };
    let SplitComponent;

    if (!(props.splitName in SplitListMap)) {
        SplitComponent = HomePage;
    } else {
        SplitComponent = SplitListMap[props.splitName];
    }
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;